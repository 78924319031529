<template>
  <div class="app member-detail-charts">
    <Navigation/>
    <PanelSidebar/>
    <div class="panel-content member-detail-charts-content">
      <Head :title="title" :type="type" :placeholder1="placeholder" @submit="changeMonth"/>
      <div class="member-detail-charts-content-in">
        <!-- details -->
        <div class="details">
          <div class="avatar" :style="{ backgroundImage: `url(${avatar})` }"></div>
          <div class="info">
            <div class="nickname">{{ nickname }}</div>
            <div class="i">
              <span>{{ t('member.totalMessage', { total: total }) }}</span>
              <span v-if="joinTime !== ''">{{ t('member.totalMessage', { time: joinTime }) }}</span>
              <span v-if="latestMsgTime !== ''">{{ t('member.latestMsgTime', { time: latestMsgTime }) }}</span>
            </div>
          </div>
        </div>
        <!-- charts -->
        <div class="charts" v-show="dayXData.length !== 0">
          <div ref="hourRef"></div>
          <div ref="dayRef"></div>
          <div ref="weekRef"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import echarts from 'echarts'
import Head from '../../components/PanelHead.vue'
import Navigation from '../../components/PanelNav.vue'
import PanelSidebar from '../../components/PanelSidebar.vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { useDialog } from '../../utils/dialog.js'
import { memberDetailsApi } from '../../api/index.js'
import { reactive, toRefs, ref } from '@vue/reactivity'

export default {
  name: 'Member',
  components: {
    Navigation,
    PanelSidebar,
    Head
  },
  setup() {
    const { t } = useI18n()
    const Store = useStore()
    const Router = useRouter()
    const Dialog = useDialog()
    const hourRef = ref(null)
    const dayRef = ref(null)
    const weekRef = ref(null)
    const data = reactive({
      title: t('member.head.title'),
      type: 'month',
      placeholder: t('member.head.placeholder'),

      total: 0,
      avatar: '',
      nickname: '',
      joinTime: '',
      latestMsgTime: '',
      hourXData: ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'],
      dayXData: [],
      weeksXData: [t('member.weeks.mon'), t('member.weeks.tues'), t('member.weeks.wed'), t('member.weeks.thur'), t('member.weeks.fri'), t('member.weeks.sat'), t('member.weeks.sun')]
    })

    const changeMonth = date => {
      if (date === '') {
        Dialog({
          content: t('member.changeMonth.error'),
          btns: [{ text: t('ok') }]
        })
        return
      }
      query(date)
    }

    const query = date => {
      Dialog({ type: 'loading' })
      memberDetailsApi({
        uid: Store.getters.getMember('uid'),
        date: date
      }).then(succ => {
        if (succ.code === 10000) {
          data.avatar = succ.data.member.avatar
          data.nickname = succ.data.member.nickname
          data.joinTime = succ.data.member.joinTime
          data.latestMsgTime = succ.data.member.latestMsgTime

          if (succ.data.daily.week === null) {
            hourRef.value.innerHTML = dayRef.value.innerHTML = weekRef.value.innerHTML = ''
            return
          }
          for (let i = 1; i <= succ.data.daily.day.length; i++) {
            data.dayXData.push(i)
          }
          succ.data.daily.week.forEach((item) => {
            data.total += item
          })
          setTimeout(() => { // dayXData 的赋值需要耗时，不延时会导致获取到高度为 0，显示不出来统计图
            echarts.init(hourRef.value, null, { renderer: 'svg' }).setOption(histOption(t('member.memberDetailsApi.hour.title'), succ.data.daily.hour, data.hourXData, t('member.memberDetailsApi.hour.xName')))
            echarts.init(dayRef.value, null, { renderer: 'svg' }).setOption(histOption(t('member.memberDetailsApi.day.title'), succ.data.daily.day, data.dayXData, t('member.memberDetailsApi.day.xName')))
            echarts.init(weekRef.value, null, { renderer: 'svg' }).setOption(histOption(t('member.memberDetailsApi.week.title'), succ.data.daily.week, data.weeksXData, t('member.memberDetailsApi.week.xName')))
          })
        } else {
          Router.push({ name: 'Panel' })
        }
      }).finally(() => {
        Dialog({ remove: true })
      })
    }

    const histOption = (title, data, xData, xName) => {
      return {
        title: {
          text: title
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          }
        },
        toolbox: {
          feature: {
            dataView: { show: true, readOnly: false },
            magicType: { show: true, type: ['bar', 'line'] },
            saveAsImage: {}
          }
        },
        xAxis: [
          {
            type: 'category',
            name: xName,
            data: xData,
            axisPointer: {
              type: 'shadow'
            }
          }
        ],
        yAxis: [
          {
            name: t('member.histOption.yAxis.name'),
            type: 'value',
            min: 0,
            axisLabel: {
              formatter: '{value} ' + t('member.histOption.yAxis.axisLabel.formatter')
            }
          }
        ],
        series: [
          {
            name: t('member.histOption.yAxis.name'),
            type: 'bar',
            data: data,
            itemStyle: {
              normal: {
                color: '#e54d26'
              }
            }
          }
        ]
      }
    }

    return { ...toRefs(data), t, hourRef, dayRef, weekRef, changeMonth }
  }
}
</script>

<style lang="scss" scoped>
.details {
  display: table;
  > div {
    display: table-cell;
    vertical-align: middle;
  }
  .avatar {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: no-repeat center center / cover gray;
  }
  .info {
    padding-left: 15px;
    .nickname {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 5px;
    }
    .i {
      font-size: 15px;
      span {
        margin-right: 30px;
      }
    }
  }
}

.charts {
  padding-top: 20px;
  > div {
    margin-top: 20px;
    height: 400px;
  }
}
</style>
